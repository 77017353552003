import React from "react"
import { graphql, PageProps } from "gatsby"
import Page from "@app/components/Maintenance/Maintenance"

export type Props = PageProps<GatsbyTypes.PagePasswordQuery, GatsbyTypes.PagePasswordQueryVariables>

export const query = graphql`
  query PagePassword {
    page: sanitySettingMaintenance {
      title
      password
      enabled
      content
      image: _rawBackgroundImage(resolveReferences: { maxDepth: 4 })
      backgroundColour {
        hex
      }
    }
  }
`
const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
