import React, { memo } from "react"
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"

import { useImage } from "@app/hooks/useImage"
import MaintenanceForm from "./MaintenanceForm"
import Main from "@app/components/Main"
import Brand from "@app/components/Brand"
import type { Props } from "@app/pages/password"
import type { PageProps } from "@root/types/global"

const Maintenance: React.FC<PageProps<Props>> = ({ location, page }) => {
  const { title, content, image, backgroundColour } = page ?? {}
  const { getGatsbyImage } = useImage()
  const responsiveImage = image?.asset ? getGatsbyImage(image, { maxWidth: 1000 }) : false

  return (
    <Main>
      <Flex minH="100vh" direction={{ base: "column", md: "row" }}>
        <Box
          display={{ base: "none", md: "block" }}
          maxW={{ base: "20rem", lg: "40rem" }}
          flex="1"
          bgColor={backgroundColour?.hex || "white"}
        >
          {image && <Image objectFit="cover" w={"100%"} h={"100%"} {...responsiveImage} />}
        </Box>

        <Box
          d={{ base: "flex", md: "block" }}
          flexDir={{ base: "column", md: "unset" }}
          justifyContent={{ base: "center", md: "unset" }}
          alignItems={{ base: "center", md: "unset" }}
          flex="1"
          px={{ base: "6", md: "10", lg: "16", xl: "28" }}
          py={{ base: "6", md: "64" }}
        >
          <Box maxW="xl" textAlign={{ base: "center", md: "left" }}>
            <Box>
              <Box mb="16" maxW="57.5" mx={{ base: "auto", md: "unset" }}>
                <Brand />
              </Box>

              <Heading as="h1" size="2xl" color="typography.headlines900">
                {title}
              </Heading>

              <Text size="largeParagraph" mt="3">
                {content && content}
              </Text>
            </Box>

            <Box mt="10" maxW="80" mx={{ base: "auto", md: "unset" }}>
              <MaintenanceForm location={location} />
            </Box>
          </Box>
        </Box>
      </Flex>
    </Main>
  )
}

export default memo(Maintenance)
