import React from "react"
import { FormControl, FormLabel, FormErrorMessage, Input, Button, Stack, Box } from "@chakra-ui/react"

import { useMaintenance } from "@app/hooks/useMaintenance"
import type { Location } from "@root/types/global"

type Props = {
  location: Location
}

const MaintenanceForm: React.FC<Props> = ({ location }) => {
  const { handleSubmit, data, handleChange, error, loading, maintenance } = useMaintenance(location)

  return (
    <Box as="form" onSubmit={handleSubmit} mb={8}>
      <Stack spacing={5}>
        <FormControl variant="floating" mb={4} isInvalid={error}>
          <Input name="password" placeholder=" " type="password" onChange={handleChange} value={data.password} />
          <FormLabel>{maintenance?.passwordLabel}</FormLabel>
          {error && <FormErrorMessage>{maintenance?.passwordErrorMessage}</FormErrorMessage>}
        </FormControl>

        <Button variant="solidSecondary" type="submit" isDisabled={loading} isLoading={loading}>
          {maintenance?.loginButtonLabel}
        </Button>
      </Stack>
    </Box>
  )
}

export default React.memo(MaintenanceForm)
